import { useEffect, useState } from 'react';
import { useTheme } from 'next-themes';
import { createTheme as createMaterialTheme } from '@mui/material/styles';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { lightPalette, darkPalette } from './palette';

export const light = {
    mode: 'light',
    primary: {
        main: lightPalette.primary[500],
        contrastText: lightPalette.white[500],
        ...lightPalette.primary,
    },
    secondary: {
        main: lightPalette.secondary[500],
        contrastText: lightPalette.white[500],
        ...lightPalette.secondary,
    },
    third: {
        main: lightPalette.third[500],
    },
    disabled: {
        main: lightPalette.disabled[500],
        ...lightPalette.disabled,
    },
    orange: {
        main: lightPalette.orange[500],
        ...lightPalette.orange,
    },
    error: {
        main: lightPalette.error[500],
    },
    white: {
        main: lightPalette.white[500],
        contrastText: lightPalette.black[500],
        ...lightPalette.white,
    },
    black: {
        main: lightPalette.black[500],
        ...lightPalette.black,
    },
};

export const dark = {
    mode: 'dark',
    primary: {
        main: darkPalette.primary[500],
        contrastText: darkPalette.white[500],
        ...darkPalette.primary,
    },
    secondary: {
        main: darkPalette.secondary[500],
        contrastText: darkPalette.white[500],
        ...darkPalette.secondary,
    },
    third: {
        main: darkPalette.third[500],
    },
    disabled: {
        main: darkPalette.disabled[500],
        ...darkPalette.disabled,
    },
    orange: {
        main: darkPalette.orange[500],
        ...darkPalette.orange,
    },
    error: {
        main: darkPalette.error[500],
    },
    white: {
        main: darkPalette.white[500],
        contrastText: darkPalette.black[500],
        ...darkPalette.white,
    },
    black: {
        main: darkPalette.black[500],
        ...darkPalette.black,
    },
};

const ThemeProvider = ({ children }) => {
    const { theme } = useTheme();
    const [mounted, setMounted] = useState(false);
    const mode = theme === undefined ? 'light' : theme === 'system' ? 'light' : theme;
    const palette = mode === 'light' ? light : dark;

    // Create a theme instance.
    const materialTheme = createMaterialTheme({
        breakpoints: {
            values: {
                sm: 640,
            }
        },
        typography: {
            fontFamily: "'Public Sans', sans-serif",

            h1: {
                fontSize: 32,
                fontWeight: 600,
            },
            h2: {
                fontSize: 24,
                fontWeight: 600,
            },
            h3: {
                fontSize: 20,
                fontWeight: 600,
            },
            h4: {
                fontSize: 18,
                fontWeight: 600,
            },
            body1: {
                fontSize: 16,
                fontWeight: 500,
            },
            body2: {
                fontSize: 14,
                fontWeight: 400,
            },
            caption: {
                fontSize: 12,
                fontWeight: 400,
                lineHeight: '14px',
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                    @font-face {
                    font-family: "'Public Sans', sans-serif";
                    }
                `,
            },
        },
        palette,
    });

    materialTheme.components = {
        ...materialTheme.components,

        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    textTransform: 'initial',
                },
                outlinedSecondary: {
                    // backgroundColor: palette.primary['50'],
                },
                outlinedWhite: {
                    color: palette.white.contrastText,
                    borderColor: palette.white.contrastText,
                },
            },
        },

        MuiRadio: {
            styleOverrides: {
                colorSecondary: {
                    color: palette.secondary.main,
                    '& + .MuiFormControlLabel-label': {
                        color: palette.black.main,
                        fontWeight: 600,
                    },
                    '&.Mui-checked + .MuiFormControlLabel-label': {
                        color: palette.secondary.main,
                    },
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                colorSecondary: {
                    color: palette.secondary.main,
                    '& + .MuiFormControlLabel-label': {
                        color: palette.black.main,
                    },
                    '&.Mui-checked + .MuiFormControlLabel-label': {
                        color: palette.secondary.main,
                        fontWeight: 600,
                    },
                },
                colorPrimary: {
                    color: palette.primary.main,
                    '& + .MuiFormControlLabel-label': {
                        color: palette.black.main,
                    },
                    '&.Mui-checked + .MuiFormControlLabel-label': {
                        color: palette.primary.main,
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                colorThird: {
                    '& + .MuiFormHelperText-root': {
                        backgroundColor: palette.primary['50'],
                        color: palette.secondary['500'],
                        margin: 0,
                        marginTop: '8px',
                        padding: '4px 12px',
                    },
                },
            },
        },
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) {
        return null;
    }

    return <MaterialThemeProvider theme={materialTheme}>{children}</MaterialThemeProvider>;
};

export default ThemeProvider;
