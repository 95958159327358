import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { handleURLQueries } from '../../navigation/function';
import MenuItem from './MenuItem';
import { SidebarContext } from '../Context/sidebarContext';
import { Popper, useMediaQuery } from '@mui/material';

const isNavActive = (router, itemHref) => {
    return router.asPath === itemHref || handleURLQueries(router, itemHref) ? true : false;
};

export default function MenuGroup(props) {
    const [open, setOpen] = useState(false);
    const { menu, depthLevel } = props;
    const { state: sidebarContext } = useContext(SidebarContext);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const hoverOpen = Boolean(anchorEl);

    const router = useRouter();

    useEffect(() => {
        if (menu?.children?.some((e) => e?.href === router.asPath)) {
            setOpen(true);
        }
    }, []);

    const handleClickExpand = (open) => {
        setOpen(open);
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const isCollapsed = !isMobile && sidebarContext.sidebarCollapse;

    return (
        <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <MenuItem menu={menu} depthLevel={depthLevel + 1} onClick={handleClickExpand} isActive={router.asPath === menu.href} />
            {!isCollapsed && ((menu?.children?.length > 0 && router.asPath.includes(menu.href)) || (menu?.children?.length > 0 && open && !menu.href)) && (
                <div className='w-full mt-0'>
                    {menu.children.map((item) => {
                        const isActive = isNavActive(router, item.href);
                        return <MenuGroup menu={item} depthLevel={depthLevel + 1} key={item.name} isActive={isActive} />;
                    })}
                </div>
            )}
            {menu.children?.length > 0 && isCollapsed && (
                <Popper placement='right-start' open={hoverOpen} anchorEl={anchorEl} onClose={handlePopoverClose} disablePortal style={{width: 250}}>
                    <div className='bg-white border rounded-md border-solid border-primary-200'>
                        {menu.children.map((item) => {
                            const isActive = isNavActive(router, item.href);
                            return <MenuGroup menu={item} depthLevel={depthLevel + 1} key={item.name} isActive={isActive} />;
                        })}
                    </div>
                </Popper>
            )}
        </div>
    );
}
