import { 
    useState, 
    useEffect, 
    useContext 
} from 'react';

import { RequestNextApi } from '../../api/Request';
import { getCookie } from '../../utils/cookieHandle';
import { UserContext } from '../Context/userContext';

const useFreshChat = () => {
    const { state: userContext } = useContext(UserContext);
    const [status, setStatus] = useState("(1)Loading...")

    const initFreshChat = (e) => {
        window.fcWidget.init({
            token: "0662c54c-1874-4e39-b352-69f2005eafad",
            host: "https://kingdomadvisors.freshchat.com",
        });
    };


    useEffect(() => {
        let interval = setInterval(() => {
            if (window?.fcWidget?.init !== undefined) {
               try {

                // eslint-disable-next-line no-use-before-define
                /* eslint-disable no-alert, no-console */
              
        window?.fcWidget?.init({
            token: "0662c54c-1874-4e39-b352-69f2005eafad",
            host: "https://kingdomadvisors.freshchat.com",
        });

              //  initFreshChat("value")
                // window.onerror = function(e) {
                //     console.log("Error: ", e);
                //   };
                // window?.fcWidget?.init({
                //     token: "0662c54c-1874-4e39-b352-69f2005eafad",
                //     host: "https://kingdomadvisors.freshchat.com",
                // });
                
                // Your code that is causing the Uncaught TypeError
              } catch (e) {
                console.log("ERRROR: ", e);
           
              }

                clearInterval(interval);
            }
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    /**
   * This will set the user into fresh chat
   */
    useEffect(() => {
        if (!window.fcWidget) {
            setStatus("(2)widget is loading")
            return;
        }

        if (userContext?.user?.id === undefined) {
            setStatus("(3) widget is loaded but user is not logged in")
            return;
        }

        setStatus("(4) widget is loaded and user is being set into the widget")

       // window.fcWidget.on('user:created',);
        window.fcWidget.user.setProperties({
            externalId: userContext?.user.id,
            firstName: userContext?.user.firstName,
            lastName: userContext?.user.lastName,
            email: userContext?.user.email,
            plan: userContext?.user?.memberType,
            Member_Exp: userContext?.user?.dateExpires,
            CKA_Exp: userContext?.user?.dateCertifiedExpires,
            KA_Champion: userContext?.user?.isChampion,
        });
        window.fcWidget.user.isExists().then((freshchatUser) => {
            // console.log("Current isExists User:", freshchatUser);
        }).catch((error) => {
            // console.log("isExists Error", error)
        });

        window.fcWidget.user.get().then((freshchatUser) => {
            // console.log("Current Freshchat User:", freshchatUser);
        }).catch((error) => {
            // console.log("Error", error)
        });

    }, [userContext]);
    return (status)
};


export default function FreshChat(props) {
    const { state: userContext, update: updateUser } = useContext(UserContext);
    const cookie = getCookie('jwt-token')
    const [continueToWidget, setContinue] = useState(null);
     useFreshChat()

    /**
     * This useEffect is used
     * to get and update the userContext 
     * if the cookie exists but the user is 
     * not to context. This will mostly be used
     * for when the page is reloaded and user token is
     * already set. 
     */
    useEffect(() => {
        if (!continueToWidget) {
            const fetchData = async () => {
                if (cookie !== undefined && !userContext?.user?.id) {

                    const userInfo = await RequestNextApi.post('/user/user-fetch2',
                        {
                            jwtToken: cookie
                        }
                    );
                    if (userInfo.data.success) {
                        updateUser({ user: userInfo.data.user });
                    }
                }
            }
            fetchData();
            setContinue(true);
        }
    }, [])

    return (
        <div />
    )
}