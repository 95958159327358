export const annualConfNavigation = {
    annualConference: {
        name: 'Annual Conference',
        href: '/members/annual-conference'
    },
    schedule: {
        name: 'Schedule',
        href: '/members/annual-conference/schedule'
    },
    agenda: {
        name: 'Agenda',
        href: '/members/annual-conference/schedule/agenda'
    },
    myAgenda: {
        name: 'My Agenda',
        href: '/members/annual-conference/schedule/my-agenda'
    },
    
    //conference experience
    confExp: {
        name: 'Conference Experience',
        href: '/members/annual-conference/conference-experience'
    },
    wifi: {
        name: 'WiFi and Social Media',
        href: '/members/annual-conference/conference-experience/wifi'
    },
    prayer: {
        name: 'Prayer Requests',
        href: '/members/annual-conference/conference-experience/prayer'
    },
    knowBeforeYouGo: {
        name: 'Know Before You Go',
        href: '/members/annual-conference/conference-experience/know-before-you-go'
    },
    ce: {
        name: 'CE Credit',
        href: '/members/annual-conference/conference-experience/ce'
    },
    award: {
        name: 'Larry Burkett Award',
        href: '/members/annual-conference/conference-experience/award'
    },
    tips: {
        name: 'Conference Tips',
        href: '/members/annual-conference/conference-experience/tips'
    },
    jobFair: {
        name: 'College Student Job Fair',
        href: '/members/annual-conference/conference-experience/job-fair'
    },
    findYourCommunity: {
        name: 'Find Your Community',
        href: '/members/annual-conference/conference-experience/annual-conference-community',
    },

    // your next step
    nextStep: {
        name: 'You Next Step',
        href: '/members/annual-conference/next-step'
    },
    community: {
        name: 'Find Your Community',
        href: '/members/annual-conference/next-step/find-your-community'
    },
    survey: {
        name: 'Conference Survey',
        href: '/members/annual-conference/next-step/survey'
    },
    nextConf: {
        name: '2025 Conference Information',
        href: '/members/annual-conference/next-step/next-conference'
    },
    becomeCka: {
        name: 'Become a CKA®',
        href: '/members/annual-conference/next-step/become-a-cka'
    },
    getInvolved: {
        name: 'Get Involved',
        href: '/members/annual-conference/next-step/get-involved'
    },

    // hotel and map
    hotelAndMap: {
        name: 'Hotel and Map',
        href: '/members/annual-conference/hotel-and-map'
    },
    maps: {
        name: 'Conference Map',
        href: '/members/annual-conference/hotel-and-map/map'
    },
    marriott: {
        name: 'Marriott World',
        href: '/members/annual-conference/hotel-and-map/marriott'
    },

    // industry partners
    industryPartners: {
        name: 'Industry Partners',
        href: '/members/annual-conference/industry-partners'
    },
    giveaways: {
        name: 'Giveaways',
        href: '/members/annual-conference/industry-partners/giveaways'
    },
    exhibitors: {
        name: 'Exhibitors',
        href: '/members/annual-conference/industry-partners/exhibitors'
    },
    exhibitHall: {
        name: 'Exhibit Hall Map',
        href: '/members/annual-conference/industry-partners/exhibit-hall-map'
    },
    
    recordings: {
        name: 'Recordings',
        href: '/members/annual-conference/recordings'
    },
    
    // ticket dashboard
    ticketDashboard: {
        name: 'Ticket Dashboard',
        href: '/members/annual-conference/dashboard'
    },

    faq: {
        name: 'FAQ',
        href: '/members/annual-conference/faq'
    }
};
