import { createCtx } from './contextFactory';

const [ctx, Provider] = createCtx({
    sessionTags: [],
    contentTags: [],
    agendaDetailId: null,
    
    /**
     * contains the main page details - agenda lists, scroll position and etc(used when to back from detail to main page).
     */
    agendaPage: null, 
});

export const AgendaContext = ctx;

export const AgendaProvider = Provider;
