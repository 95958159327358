import Cookies from 'js-cookie';

export const getCookie = (name) => {
    return Cookies.get(name);
};

export const setCookie = (name, value, opt) => {
    return Cookies.set(name, value, opt);
};

export const removeCookie = (name) => {
    return Cookies.remove(name);
}

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const validateToken = (token) => {
    let decodedJwt = parseJwt(token);
    decodedJwt = decodedJwt ? decodedJwt : {...decodedJwt, exp: 0};
    return ((decodedJwt.exp * 1000 < Date.now()) || !decodedJwt) ? false : true;
};

export const getUser = () => {
    let user = parseJwt(getCookie('jwt-token'));
    user = user ? user : {...user, exp: 0};
    return user;
};
