import React, { useContext, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import { ListItemButton, ListItemText, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore, Lock } from '@mui/icons-material';

import { classNames } from '../../navigation';
import { UserContext } from '../Context/userContext';
import { SidebarContext } from '../Context/sidebarContext';

export default function MenuItem(props) {
    const [open, setOpen] = React.useState(false);
    const { state: userContext } = useContext(UserContext);
    const { state: sidebarContext } = useContext(SidebarContext);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const router = useRouter();

    const { menu, onClick, depthLevel, isActive, ...restProps } = props;

    const activeClasses = useMemo(() => (depthLevel === 1 ? 'bg-secondary-500 text-white' : 'bg-primary-50 border-primary-100 border border-solid text-black-400'), [depthLevel]);
    const hoverClasses = useMemo(() => (depthLevel === 1 ? 'hover:bg-secondary-500 hover:text-white' : 'hover:bg-primary-50 hover:border-primary-100 hover:border-solid'), [depthLevel]);

    useEffect(() => {
        if (menu?.children?.some((e) => e?.href === router.asPath)) {
            setOpen(true);
        }
    }, []);

    const handleClick = () => {
        if (menu.href) {
            router.replace(menu.href);
        } else {
            setOpen(!open);
            onClick(!open);
        }
    };

    return (
        <ListItemButton
            alignItems='flex-start'
            onClick={handleClick}
            className={classNames(isActive ? activeClasses : 'text-black-300', `${hoverClasses} group border-solid flex items-center px-2 py-2.5 font-medium rounded-md`, depthLevel > 1 ? 'border border-transparent' : '')}
            {...restProps}
        >
            {menu.icon && <menu.icon className='mr-3 flex-shrink-0 h-6 w-6' aria-hidden='true' />}
            <ListItemText
                primary={menu.name}
                primaryTypographyProps={{
                    fontSize: 15,
                    fontFamily: "'Public Sans', sans-serif",
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    marginLeft: depthLevel === 1 ? `${2.5 * (depthLevel - 1)}rem` : 6,
                    marginRight: depthLevel === 1 ? 0 : 2,
                }}
                sx={{ my: 0, display: depthLevel === 1 && !isMobile && sidebarContext.sidebarCollapse ? 'none' : 'block' }}
            />
            {menu.memberOnly && userContext?.user && !userContext?.user?.hasMemberPermission && <Lock fontSize='small' />}
            {menu?.children?.length > 0 && (router.asPath.includes(menu.href) || open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
    );
}
