import {
    CalendarMonthOutlined,
    DvrOutlined,
    FolderOutlined,
    GroupOutlined,
    Handshake,
    HomeOutlined,
    InboxOutlined,
    LocalLibrary,
    Login,
    Logout,
    MailOutline,
    ManageAccounts,
    NotificationsNone,
    PublicOutlined,
    School,
    SettingsOutlined,
    WorkOutlineOutlined,
} from '@mui/icons-material';

export const navigation = [
    {
        name: 'Home',
        href: '/members/home',
        icon: HomeOutlined,
        external: false,
        children: [],
    },
    {
        name: 'Annual Conference',
        href: null,
        icon: GroupOutlined,
        external: false,
        children: [
            {
                name: 'Conference Home',
                href: '/members/annual-conference',
            },
            {
                name: 'Schedule',
                href: null,
                children: [
                    {
                        name: 'Agenda',
                        href: '/members/annual-conference/schedule/agenda',
                    },
                    {
                        name: 'My Agenda',
                        href: '/members/annual-conference/schedule/my-agenda',
                    },
                ],
            },
            {
                name: 'Industry Partners',
                href: null,
                children: [
                    {
                        name: 'Exhibitors',
                        href: '/members/annual-conference/industry-partners/exhibitors',
                    },
                    {
                        name: 'Exhibit Hall Map',
                        href: '/members/annual-conference/industry-partners/exhibit-hall-map',
                    },
                    {
                        name: 'Giveaways',
                        href: '/members/annual-conference/industry-partners/giveaways',
                    },
                ],
            },
            {
                name: 'Conference Experience',
                href: null,
                children: [
                    {
                        name: 'Know Before You Go',
                        href: '/members/annual-conference/conference-experience/know-before-you-go',
                    },
                    {
                        name: 'Find Your Community',
                        href: '/members/annual-conference/conference-experience/annual-conference-community',
                    },
                    {
                        name: 'Conference Tips',
                        href: '/members/annual-conference/conference-experience/tips',
                    },
                    {
                        name: 'College Student Job Fair',
                        href: '/members/annual-conference/conference-experience/job-fair',
                    },
                    {
                        name: 'CE Credit',
                        href: '/members/annual-conference/conference-experience/ce',
                    },
                    {
                        name: 'WiFi and Social Media',
                        href: '/members/annual-conference/conference-experience/wifi',
                    },
                    {
                        name: 'Larry Burkett Award',
                        href: '/members/annual-conference/conference-experience/award',
                    },
                    {
                        name: 'Prayer Requests',
                        href: '/members/annual-conference/conference-experience/prayer',
                    },
                ],
            },
            {
                name: 'Your Next Step',
                href: null,
                children: [
                    {
                        name: 'Become a CKA®',
                        href: '/members/annual-conference/next-step/become-a-cka',
                    },
                    {
                        name: 'Get Involved',
                        href: '/members/annual-conference/next-step/get-involved',
                    },
                    {
                        name: 'Conference Survey',
                        href: '/members/annual-conference/next-step/survey',
                    },
                    {
                        name: '2025 Conference Information',
                        href: '/members/annual-conference/next-step/next-conference',
                    },
                ],
            },
            {
                name: 'Hotel and Map',
                href: null,
                children: [
                    {
                        name: 'Marriott World',
                        href: '/members/annual-conference/hotel-and-map/marriott',
                    },
                    {
                        name: 'Conference Map',
                        href: '/members/annual-conference/hotel-and-map/map',
                    },
                ],
            },
            {
                name: 'Recordings',
                href: '/members/annual-conference/recordings',
            },
            {
                name: 'Ticket Dashboard',
                href: '/members/annual-conference/dashboard',
            },
            {
                name: 'FAQ',
                href: '/members/annual-conference/faq',
            },
        ],
    },
    {
        name: 'Community',
        href: null,
        icon: PublicOutlined,
        external: false,
        children: [
            {
                name: 'Community Home',
                href: '/members/community',
            },
            {
                name: 'Connections',
                href: '/members/community/connections',
                memberOnly: true,
            },
            {
                name: 'Discussion Groups',
                href: '/members/community/discussion-groups',
                memberOnly: true,
            },
            {
                name: 'Study Groups',
                href: '/members/community/study-groups',
            },
            {
                name: 'My Study Group',
                href: '/members/community/my-study-group',
            },
        ],
    },
    {
        name: 'Practice Management',
        href: null,
        icon: WorkOutlineOutlined,
        external: false,
        memberOnly: true,
        children: [
            {
                name: 'Job Board',
                href: '/members/practice-management/jobs',
                memberOnly: true,
            },
        ],
    },
    {
        name: 'Resources',
        href: null,
        icon: FolderOutlined,
        external: true,
        children: [
            {
                name: 'Experts Library',
                href: '/members/resources/library',
            },
            {
                name: 'Favorites',
                href: '/members/resources/favorites',
            },
            {
                name: 'Courses',
                href: '/members/resources/courses',
            },
        ],
    },
    {
        name: 'Leader Tools',
        href: null,
        icon: InboxOutlined,
        external: true,
        children: [
            {
                name: 'Leader Tools Home',
                href: '/members/leader-tools',
            },
            {
                name: 'Study Group Leader',
                href: '/members/leader-tools/study-group-leader',
            },
            {
                name: 'Partner Hub',
                href: '/members/leader-tools/partner-hub',
            },
        ],
    },
    {
        name: 'Account',
        href: null,
        icon: SettingsOutlined,
        external: true,
        children: [
            {
                name: 'Account Home',
                href: '/members/account',
            },
            {
                name: 'Contact Information',
                href: '/members/account/contact',
            },
            {
                name: 'Profile',
                href: '/members/account/profile',
            },
            {
                name: 'Payments',
                href: '/members/account/payments',
            },
            {
                name: 'Certification',
                href: '/members/account/certification',
            },
            {
                name: 'Communication Preferences',
                href: '/members/account/communication-preferences',
            },
            {
                name: 'Email & Password',
                href: '/members/account/email-password',
            },
            {
                name: 'Transaction History',
                href: '/members/account/transaction-history',
            },
            {
                name: 'CE Credit History',
                href: '/members/account/ce',
            },
        ],
    },
];

export const staffNavigation = [
    {
        name: 'Staff Home',
        href: '/staff',
        icon: DvrOutlined,
        external: false,
    },
    {
        name: 'CRM',
        href: '/staff/user',
        icon: DvrOutlined,
        external: false,
    },
    {
        name: 'Partners',
        href: '/staff/partner',
        icon: DvrOutlined,
        external: false,
    },
    {
        name: 'Champions',
        href: '/staff/champion',
        icon: DvrOutlined,
        external: false,
    },
];
export const userAccountMenu = [
    {
        name: 'My Dashboard',
        href: '/members/home',
        icon: HomeOutlined,
    },
    {
        name: 'Sign In/Out',
        href: '/login',
        icon: Logout,
    },
];

export const staffUserAccountMenu = [
    {
        name: 'My Dashboard',
        href: '/members/home',
        icon: HomeOutlined,
    },
    {
        name: 'Staff Home',
        href: '/staff',
        icon: DvrOutlined,
    },
    {
        name: 'Staff CRM',
        href: '/staff/user',
        icon: ManageAccounts,
    },
    {
        name: 'Partner Dashboard',
        href: '/staff/partner',
        icon: Handshake,
    },
    {
        name: 'Champions Dashboard',
        href: '/staff/champion',
        icon: Handshake,
    },
    {
        name: 'Sign In/Out',
        href: '/login',
        icon: Logout,
    },
];

export const topNavigation = [
    { name: 'Find a Professional', href: '/find' },
    { name: 'CKA Designation', href: '/cka' },
    { name: 'Membership', href: '/member' },
    { name: 'Events', href: '/events' },
    { name: 'Training', href: '/traning' },
];
