import PropTypes from 'prop-types';
import { Analytics } from '@vercel/analytics/react';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as NextThemeProvider } from 'next-themes';

import { SnackbarProvider } from 'notistack';

import { AgendaProvider } from '../components/Context/agendaContext';
import { NotificationProvider } from '../components/Context/notificationContext';
import { MembershipJoinProvider } from '../components/Context/membershipJoinContext';
import { ProgressProvider } from '../components/Context/progressContext';
import { PurchaseTicketProvider } from '../components/Context/ticketContext';
import { UserProvider } from '../components/Context/userContext';
import { GlobalsProvider } from '../components/Context/globalsContext';
import FreshChat from '../components/FreshChat/FreshChat';
import Layout from '../components/Layout';
import ProgressBar from '../components/ProgressBar';
import { RouteGuard } from '../components/Route/RouteGuard';
import Navigation from '../navigation';
import createEmotionCache from '../src/createEmotionCache';
import ThemeProvider from '../src/theme';
import { ShowNotificationConfigurator } from '../utils/notification/snackbarUtils';

import '../styles/globals.scss';
import { PusherProvider } from '../components/Context/pusherContext';
import { SaleSaveProvider } from '../components/Context/saleSaveContext';
import { SidebarProvider } from '../components/Context/sidebarContext';
import '../tailwind.css'
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function MyApp(props) {
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

    const getLayout =
        Component.getLayout ??
        ((page) => (
            <Navigation>
                <Layout>{page}</Layout>
            </Navigation>
        ));

    if (process.env.ENV !== 'development') {
        console.log = () => {
            //
        };
    }

    return (
        <CacheProvider value={emotionCache}>
            <NextThemeProvider attribute='class' forcedTheme='light'>
                <ThemeProvider>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <ProgressProvider>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                            <NotificationProvider>
                                {' '}
                                {/* currently not used, using local state instead*/}
                                <PurchaseTicketProvider>
                                    <UserProvider>
                                        <GlobalsProvider>
                                            <AgendaProvider>
                                                <SidebarProvider>
                                                    <MembershipJoinProvider>
                                                        <SaleSaveProvider>
                                                            <PusherProvider>
                                                                <ShowNotificationConfigurator />
                                                                <ProgressBar />
                                                                <FreshChat />
                                                                {Component.isPublic ? getLayout(<Component {...pageProps} />) : <RouteGuard isStaff={Component.isStaff}>{getLayout(<Component {...pageProps} />)}</RouteGuard>}
                                                                <Analytics />
                                                            </PusherProvider>
                                                        </SaleSaveProvider>
                                                    </MembershipJoinProvider>
                                                </SidebarProvider>
                                            </AgendaProvider>
                                        </GlobalsProvider>
                                    </UserProvider>
                                </PurchaseTicketProvider>
                            </NotificationProvider>
                        </SnackbarProvider>
                    </ProgressProvider>
                </ThemeProvider>
            </NextThemeProvider>
        </CacheProvider>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    emotionCache: PropTypes.object,
    pageProps: PropTypes.object.isRequired,
};

export default MyApp;
