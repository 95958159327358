import React, { Fragment, useCallback, useContext, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { useRouter } from 'next/router';

import { IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';

import { ArrowBackIosNew, ArrowForwardIos, Close, ArrowCircleRight, ArrowCircleLeft, MenuOutlined } from '@mui/icons-material';

import NotificationMenu from '../components/TopNav/NotificationMenu';
import UserAccountMenu from '../components/TopNav/UserAccountMenu';
import SideNav from '../components/SideNav/SideNav';
import Footer from './components/Footer';
import { navigation } from './navigation';
import { UserContext } from '../components/Context/userContext';
import { SidebarContext } from '../components/Context/sidebarContext';
import { ProgressContext } from '../components/Context/progressContext';

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const DynamicSideNav = ({ isChampion }) => {
    const [navItems, setNavItems] = useState(navigation);

    React.useEffect(() => {
        if (isChampion) {
            const cloned = [...navigation];
            const accountIndex = cloned.findIndex((item) => item.name === 'Account');
            if (accountIndex > -1) {
                cloned[accountIndex].children.push({
                    name: 'Champions',
                    href: '/members/account/champions',
                });
                setNavItems(cloned);
            }
        } else {
            setNavItems(navigation);
        }
    }, [isChampion])

    return (
        <SideNav navItems={navItems} />
    );

}
export default function Navigation({ children }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { update: updateSidebarContext, state: sidebarContext } = useContext(SidebarContext);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const router = useRouter();
    const { state: progressContext } = useContext(ProgressContext);
    const loggedIn = true;

    const onClickLogo = () => {
        router.push('/');
    };

    const { state: userContext } = useContext(UserContext);

    React.useEffect(() => {
        // Have the side bar open by default
        updateSidebarContext({
            sidebarCollapse: false,
        })
    }, [])

    const renderName = useCallback(() => {
        if (userContext?.user) {
            const firstName = userContext?.user?.firstName || userContext?.user?.friendlyName;
            if (firstName) {
                return (
                    <Typography
                        variant='h3'
                        sx={{
                            fontSize: {
                                xs: '14px',
                                sm: '20px',
                            },
                        }}
                    >
                        Hello, {userContext?.user?.firstName || userContext?.user?.friendlyName}
                    </Typography>
                );
            }
        }
    }, [userContext]);

    const handleMenuToggle = (sidebarCollapse) => {
        updateSidebarContext({
            sidebarCollapse,
        });
    };

    const isCollapsed = !isMobile && sidebarContext.sidebarCollapse;

    return (
        <>
            {/* Mobile Menu */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as='div' className='relative z-40 sm:hidden' onClose={setSidebarOpen}>
                    <Transition.Child as={Fragment} enter='transition-opacity ease-linear duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='transition-opacity ease-linear duration-300' leaveFrom='opacity-100' leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>
                    <div className='fixed inset-0 flex z-40'>
                        <Transition.Child
                            as={Fragment}
                            enter='transition ease-in-out duration-300 transform'
                            enterFrom='-translate-x-full'
                            enterTo='translate-x-0'
                            leave='transition ease-in-out duration-300 transform'
                            leaveFrom='translate-x-0'
                            leaveTo='-translate-x-full'
                        >
                            <Dialog.Panel className='relative flex-1 flex flex-col w-full px-3 pt-3 pb-4 bg-white'>
                                <div className='flex justify-end mr-4'>
                                    <IconButton onClick={() => setSidebarOpen(false)} size='large' className='mx-1 sm:hidden'>
                                        <Close />
                                    </IconButton>
                                </div>
                                <div className='flex-shrink-0 flex items-center px-4 mt-2'>
                                    <img src='/static/images/logo.png' alt='Kingdom Advisor Logo' width={250} className='h-auto' onClick={onClickLogo} />
                                </div>
                                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                                    <DynamicSideNav isChampion={userContext?.user?.isChampion} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>

                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                        <div className='flex-shrink-0 w-14' aria-hidden='true'></div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* Desktop Menu */}
            {!isMobile && loggedIn ? (
                <div className={`sm:flex ${isCollapsed ? 'sm:w-24' : 'sm:w-80'} sm:flex-col sm:fixed sm:inset-y-0 h-full z-10`}>
                    <div className={`${isCollapsed ? "overflow-visible" : "overflow-auto"} flex flex-col flex-grow border-solid border-r border-l-0 px-3 border-t-0 border-b-0 border-gray-50 pt-4 bg-white`}>
                        <div className='flex items-center flex-shrink-0 px-2 w-full'>
                            <img src={isCollapsed ? '/static/images/ka-tm.png' : '/static/images/logo.png'} alt='Kingdom Advisor Logo' height={38} onClick={onClickLogo} />
                        </div>
                        <div className={`${isCollapsed ? "justify-start" : "justify-end"} flex mt-2`}>
                            <IconButton onClick={() => handleMenuToggle(!sidebarContext.sidebarCollapse)}>{sidebarContext.sidebarCollapse ? <ArrowCircleRight color='secondary' fontSize='large' /> : <ArrowCircleLeft color='secondary' fontSize='large' />}</IconButton>
                        </div>
                        <div className='mt-3 flex-grow flex flex-col space-y-4'>
                            <DynamicSideNav isChampion={userContext?.user?.isChampion} />
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className={` ${loggedIn ? (isCollapsed ? 'sm:pl-24' : 'sm:pl-80') : ''} flex flex-col flex-1 justify-between h-screen`}>
                <div className='sticky top-0 bg-white z-10 flex-shrink-0 flex h-16 bg-sta-400 shadow'>
                    <div className='flex items-center'>
                        <Tooltip title='Menu'>
                            <IconButton onClick={() => setSidebarOpen(true)} size='large' className='mx-1 sm:hidden'>
                                <MenuOutlined />
                            </IconButton>
                        </Tooltip>
                        <img src='/static/images/logo.png' alt='Kingdom Advisor Logo' height={'auto'} onClick={onClickLogo} className='sm:hidden w-40 sm:w-64' />
                    </div>
                    <div className='flex-1 sm:px-4 flex justify-between bg-white items-center'>
                        <div className={'flex flex-row justify-start items-end w-full'}>{renderName()}</div>
                        <div className='ml-4 flex items-center sm:ml-6'>
                            <NotificationMenu />
                            <UserAccountMenu />
                        </div>
                    </div>
                </div>
                <div className='relative flex flex-col flex-1 overflow-scroll'>
                    <main className='flex-1 overflow-scroll'>{children}</main>
                    <Footer />
                </div>
            </div>
        </>
    );
}
