import axios from "axios";

import { 
    getCookie, 
    setCookie 
} from "../utils/cookieHandle";

export const RequestNextApi = axios.create({
    baseURL: `${process.env.HOST_URL}/api`
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

RequestNextApi.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        const failedRequest = err.config;
        
        if (err.response.status === 401 && failedRequest.url.includes('login')) {
            return Promise.reject(err);
        }

        if (err.response.status === 401 && !failedRequest._retry) {
            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        let failedRequestData = JSON.parse(failedRequest.data);
                        let newData = {
                            ...failedRequestData,
                            jwtToken: token
                        };
                        failedRequest.data = newData;
                        return RequestNextApi(failedRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            failedRequest._retry = true;
            isRefreshing = true;

            return new Promise(function(resolve, reject) {
                RequestNextApi.post(
                    '/login/refreshtoken', 
                    {
                        token: getCookie('jwt-refresh-token'),
                        remote_addr: getCookie('remote_addr')
                    })
                    .then((res) => {
                        setCookie('jwt-token', res.data.jwtToken);
                        setCookie('jwt-refresh-token', res.data.refreshToken, {expires: 3});
                        let failedRequestData = JSON.parse(failedRequest.data);
                        let newData = {
                            ...failedRequestData,
                            jwtToken: res.data.jwtToken
                        };
                        failedRequest.data = newData;
                        processQueue(null, res.data.jwtToken);
                        resolve(RequestNextApi(failedRequest));
                    })
                    .catch(err => {
                        window.location.href = (`${process.env.HOST_URL}/login`);
                        processQueue(err, null);
                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(err);
    }
);

export const KingdomApi = axios.create({
    baseURL: process.env.API_URL,
    headers: {
        'X-App-Authorization': `Application ${process.env.APP_TOKEN}`, 
        // 'Host': process.env.HOST_DOMAIN, 
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
    }
});
