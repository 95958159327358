import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { getCookie, parseJwt } from '../../utils/cookieHandle';
import { UserContext } from '../Context/userContext';

export { RouteGuard };

function RouteGuard({ children, isStaff }) {
    const router = useRouter();
    const [authorized, setAuthorized] = useState(false);
    const { state: userContext } = useContext(UserContext);

    useEffect(() => {
        // on initial load - run auth check
        if (router.isReady) {
            authCheck(router.asPath);

            // on route change start - hide page content by setting authorized to false
            // router.events.on('routeChangeStart', hideContent);

            // on route change complete - run auth check
            router.events.on('routeChangeComplete', authCheck);
        }

        // unsubscribe from events in useEffect return function
        return () => {
            // router.events.off('routeChangeStart', hideContent);
            router.events.off('routeChangeComplete', authCheck);
        };
    }, [router.isReady]);

    // const hideContent = () => setAuthorized(false);

    function authCheck(url) {
        // redirect to login page if accessing a private page and not logged in
        let decodedJwt = parseJwt(getCookie('jwt-token'));
        decodedJwt = decodedJwt ? decodedJwt : { ...decodedJwt, exp: 0 };

        if (decodedJwt.exp * 1000 < Date.now() && !getCookie('jwt-refresh-token')) {
            // token expired
            setAuthorized(false);
            router.push({
                pathname: '/login',
                query: { returnUrl: router.asPath },
            });
        } else {
            setAuthorized(true);
        }
    }

    useEffect(() => {
        if (userContext?.user && isStaff && userContext.user.isStaff !== 1) {
            router.push({
                pathname: '/members/home',
            });
        }
    }, [userContext, isStaff]);

    if (isStaff) {
        return authorized && userContext?.user?.isStaff === 1 && children;
    }

    return authorized && children;
}
