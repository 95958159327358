import React from 'react';

export function createCtx(defaultValue) {
  const defaultUpdate = () => defaultValue;
  const ctx = React.createContext({
    state: defaultValue,
    update: defaultUpdate,
  });
  function Provider(props) {
    const [state, update] = React.useState(defaultValue);
    return <ctx.Provider value={{ state, update }} {...props} />;
  }
  return [ctx, Provider]; // alternatively, [typeof ctx, typeof Provider]
}
