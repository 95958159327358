import {
    useContext,
    useEffect,
    useState
} from 'react';
import { useRouter } from 'next/router';

import useInterval from 'use-interval';

import {
    Badge,
    Divider,
    IconButton,
    ListItemIcon,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';

import {
    Clear,
    NotificationsNone,
} from '@mui/icons-material';

import { RequestNextApi } from '../../api/Request';
import { communityNavigation } from '../../navigation/community';
import { getCookie } from '../../utils/cookieHandle';
import { ShowNotification } from '../../utils/notification/snackbarUtils';
import { DISCUSSION_TYPE } from '../../utils/discussions/model';
import { annualConfNavigation } from '../../navigation/annual-conference';
import { ProgressContext } from '../Context/progressContext';
import { PusherContext } from '../Context/pusherContext';
import { StyledMenu } from './StyledMenu';

export default function NotificationMenu(props) {
    const { state: progressContext, update: updateProgress } = useContext(ProgressContext);
    const { state: pusherContext, update: updatePusherContext } = useContext(PusherContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState();
    const open = Boolean(anchorEl);

    const router = useRouter();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchNotifications = async () => {
        try {
            const res = await RequestNextApi.post('/discussion/discussion-fetch-notifications',
                {
                    jwtToken: getCookie('jwt-token')
                }
            );
            if (res.data && res.data.success) {
                setNotifications(res.data.results);
            } else {
                ShowNotification.error(res.data.message);
            }
        } catch (error) {
            // 
        }
    };

    const fetchOnlineUsers = async () => {
        try {
            const res = await RequestNextApi.post('/pusher/fetch-online-users',
                {
                    jwtToken: getCookie('jwt-token')
                }
            );
            if (res.data && res.data.success) {
                updatePusherContext({
                    ...pusherContext,
                    onlineUsers: res.data.userIds
                });
            } else {
                ShowNotification.error(res.data.message);
            }
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        fetchNotifications();
        fetchOnlineUsers();
    }, []);

    useInterval(async () => {
        await fetchNotifications();
    }, 50000);

    const handleClearNotification = async (item) => {
        updateProgress({ isLoading: true });
        try {
            const res = await RequestNextApi.post('/discussion/discussion-clear-notification',
                {
                    id: item.id,
                    jwtToken: getCookie('jwt-token')
                }
            );
            if (res.data && res.data.success) {
                const newNotifications = notifications.filter((e) => e.id !== item.id);
                setNotifications(newNotifications);
                ShowNotification.success(res.data.message);
            } else {
                ShowNotification.error('Clearing notification failed');
            }
        } catch (error) {
            ShowNotification.error('Clearing notification failed');
        }
        updateProgress({ isLoading: false });
    };

    const handleNotificationRouting = (item) => {
        switch (item.groupType) {
            case DISCUSSION_TYPE.ONE_ON_ONE:
                router.push(
                    {
                        pathname: `${communityNavigation.discussionGroups.href}/${item.groupId}`,
                        query: {
                            'type': DISCUSSION_TYPE.ONE_ON_ONE,
                        },
                    }
                );
                break;
            case DISCUSSION_TYPE.PUBLIC:
            case DISCUSSION_TYPE.PRIVATE:
                router.push(
                    {
                        pathname: `${communityNavigation.discussionGroups.href}/${item.groupId}`,
                        query: {
                            'commentId': item.commentId,
                        },
                    },
                );
                break;
            case DISCUSSION_TYPE.AGENDA:
                router.push(
                    {
                        pathname: `${annualConfNavigation.agenda.href}/${item.resourceId}`,
                        query: {
                            'commentId': item.commentId,
                        },
                    }
                );
                break;
            case DISCUSSION_TYPE.STUDY:
                router.push(
                    {
                        pathname: `${communityNavigation.studyGroups.href}/${item.groupId}`,
                        query: {
                            'commentId': item.commentId,
                        },
                    }
                );
                break;
        }
    }

    const handleClickNotification = async (item) => {
        handleNotificationRouting(item);
        const newNotifications = notifications.filter((e) => e.id !== item.id);
        setNotifications(newNotifications);
        handleClose();
    };

    return (
        <>
            <Tooltip title='Notifications'>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'notification-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Badge badgeContent={notifications?.length} color='error'>
                        <NotificationsNone />
                    </Badge>
                </IconButton>
            </Tooltip>
            <StyledMenu
                anchorEl={anchorEl}
                id="notification-menu"
                open={open}
                onClose={handleClose}
            >
                {notifications?.length > 0 ? (
                    notifications.map((item, index) => {

                        return (
                            <div key={index}>
                                <MenuItem
                                    className='flex justify-between gap-4'
                                    onClick={() => handleClickNotification(item)}
                                >
                                    <Typography
                                        component='div'
                                        className='whitespace-normal'
                                    >
                                        {item.groupName ? item.groupName + ": " : ""}{item.message}
                                    </Typography>

                                    <ListItemIcon
                                        className='w-[15px]'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearNotification(item)
                                        }}>
                                        <Clear fontSize="small" />
                                    </ListItemIcon>
                                </MenuItem>
                                {index !== (notifications.length - 1) && (
                                    <Divider />
                                )}
                            </div>
                        )
                    })
                ) : (
                    <MenuItem className='flex justify-between'>
                        <Typography component='div'>
                            No notifications
                        </Typography>
                    </MenuItem>
                )}
            </StyledMenu>
        </>
    )
}
