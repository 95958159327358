export const lightPalette = {
    primary: {
        50: '#D7D9DF',
        100: '#C3C8D3',
        200: '#AEB6C7',
        300: '#8693AE',
        500: '#213C72',
        700: '#0D1E40',
        900: '#02091A',
    },
    secondary: {
        500: '#213C72',
    },
    third: {
        500: '#213C72',
    },
    disabled: {
        50: '#f8f8f8',
        100: '#EBEBEB',
        500: '#bdbdbd',
    },
    orange: {
        50: '#FFF7EC',
        100: '#FEE7C4',
        300: '#FCCA80',
        400: '#FCC068',
        500: '#FBB042',
        550: '#fac710',
        600: '#E4A03C',
        700: '#B27D2F',
    },
    error: {
        500: '#ff1744',
    },
    white: {
        500: '#fff',
    },
    black: {
        50: '#E6E6E6',
        400: '#333333',
        500: '#000000',
    },
    gray: {
        50: '#F8F9FA',
        100: '#F2F3F5',
        200: '#E4E6EA',
        300: '#D0D4DA',
        400: '#9BA2AE',
        500: '#6B727F',
        600: '#4B5563',
        700: '#374151',
    },
    teal: {
        400: '#2DD3BE',
        500: '#14B7A5',
    },
    indigo: {
        200: '#C6D1FD',
        300: '#A4B3FB',
        400: '#808BF7',
        500: '#6366F0',
        600: '#4F46E4',
        700: '#4338C9',
    },
    blue: {
        400: '#60A4F9',
        900: '#1E3A89',
    },
    green: {
        50: '#F2F6F7',
        100: '#D5E4E6',
        200: '#C1D7DA',
        300: '#A5C5C9',
        400: '#94babf',
        500: '#79A9AF',
        600: '#6e9a9f',
        700: '#8E98A8',
        800: '#435d60',
        900: '#33474A',
    },
};

export const darkPalette = {
    primary: {
        50: '#1A476A',
        100: '#1A6CAA',
        200: '#1792F2',
        300: '#3CA6F9',
        500: '#AADAFF',
        700: '#EAF6FF',
        900: '#F7FBFF',
    },
    secondary: {
        500: '#AADAFF',
    },
    third: {
        500: '#AADAFF',
    },
    disabled: {
        50: '#4f4f4f',
        100: '#686868',
        500: '#cacaca',
    },
    orange: {
        50: '#694a1c',
        100: '#8a6124',
        300: '#e4a03c',
        400: '#fbb042',
        500: '#fcc068',
        600: '#fcca80',
        700: '#fddba8',
    },
    error: {
        500: '#ff4569',
    },
    white: {
        500: '#000000',
    },
    black: {
        50: '#ffffff',
        400: '#ffffff',
        500: '#ffffff',
    },
    gray: {
        50: '#111827',
        100: '#1F2937',
        200: '#374151',
        300: '#4B5563',
        400: '#6B727F',
        500: '#9BA2AE',
        600: '#D0D4DA',
        700: '#E4E6EA',
    },
    teal: {
        400: '#14B7A5',
        500: '#2DD3BE',
    },
    indigo: {
        200: '#4338C9',
        300: '#4F46E4',
        400: '#6366F0',
        500: '#808BF7',
        600: '#A4B3FB',
        700: '#C6D1FD',
    },
    blue: {
        400: '#3B81F5',
        900: '#EEF5FE',
    },
    green: {
        50: '#33474A',
        100: '#435d60',
        200: '#8E98A8',
        300: '#6e9a9f',
        400: '#79A9AF',
        500: '#94babf',
        600: '#A5C5C9',
        700: '#C1D7DA',
        800: '#D5E4E6',
        900: '#F2F6F7',
    },
};
