import { createCtx } from './contextFactory';

const [ctx, Provider] = createCtx({
    user: {},
    profile: {},
    sale: {}
});

export const SaleSaveContext = ctx;

export const SaleSaveProvider = Provider;
