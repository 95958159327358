export const communityNavigation = {
    community: {
        name: 'Community',
        href: '/members/community'
    },
    connections: {
        name: 'Connections',
        href: '/members/community/connections'
    },
    discussionGroups: {
        name: 'Discussion Groups',
        href: '/members/community/discussion-groups'
    },
    studyGroups: {
        name: 'Study Groups',
        href: '/members/community/study-groups'
    },
    myStudyGroups: {
        name: 'My Study Group',
        href: '/members/community/my-study-group'
    }
};
