import React, { useEffect } from 'react';

import { List } from '@mui/material';

import MenuGroup from './MenuGroup';
import { GlobalsContext } from '../Context/globalsContext';
import { useContext } from 'react';
import { getCookie } from '../../utils/cookieHandle';
import { RequestNextApi } from '../../api/Request';
export default function SideNav(props) {
    const { navItems } = props;
    const { update: updateGlobals, state: globalsContext } = useContext(GlobalsContext);

    useEffect(() => {
        if (globalsContext.globals === null) {
            const callGlobals = async () => {
                const globalInfo = await RequestNextApi.post('/global-variables', {
                    jwtToken: getCookie('remote_addr'),
                });

                if (globalInfo.data.success) {
                    updateGlobals({ globals: globalInfo.data.globals });
                }
            };
            callGlobals();
        }
    }, [globalsContext]);

    const filteredNavData = navItems.map((item, index) => {
        const children = item.children.filter((item) => {
            return item.name !== 'Schedule' && item.name !== 'Conference Experience' && item.name !== 'Your Next Step' && item.name !== 'Hotel and Map' && item.name !== 'Industry Partners' && item.name !== 'FAQ';
        });
        return { ...item, children: children };
    });
    const navigationItems = globalsContext?.globals?.hideConferenceMenu === true ? filteredNavData : navItems;

    if (globalsContext?.globals === null) return <></>;

    return (
        <List sx={{ width: '100%' }} className='' disablePadding>
            {navigationItems.map((item) => {
                return <MenuGroup key={item.name} menu={item} depthLevel={0} />;
            })}
        </List>
    );
}
