import PusherClient from 'pusher-js';

import { getCookie } from '../utils/cookieHandle';

export const pusherClient = new PusherClient(
    process.env.PUSHER_APP_KEY,
    {
        userAuthentication: {
            endpoint: '/api/pusher/user-auth',
            params: {
                jwtToken: getCookie('jwt-token')
            }
        },
        channelAuthorization: {
            endpoint: '/api/pusher/auth',
            params: {
                jwtToken: getCookie('jwt-token')
            }
        },
        cluster: process.env.PUSHER_APP_CLUSTER,
        forceTLS: true
    }
);
