import { createCtx } from './contextFactory';

const [ctx, Provider] = createCtx({
    tickets: [],
    donationAmount: 0,
    purchaser: null,
    paymentMethod: null
});

export const PurchaseTicketContext = ctx;

export const PurchaseTicketProvider = Provider;
