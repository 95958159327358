import { styled } from '@mui/material/styles';

import Link from '../src/Link';

const StyledHeaderLink = styled(Link)(({ theme }) => ({
    '&': {
        wordSpacing: 'normal',
        color: theme.palette.secondary['500'],
    },
    'strong': {
        background: theme.palette.disabled['100'],
        color: theme.palette.black['200'],
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 6,
        fontWeight: 500,
    }
}));  
  
export const headerLink = (link, current) => {
    return (
      <StyledHeaderLink href={link.href} underline="none">
          {current ? 
            <strong>{link.name}</strong> :
            <>{link.name}</>
          }
      </StyledHeaderLink>
    )
};

export const handleURLQueries = (router, path) => {
  if (router.pathname.includes('[pid]')) {
    let array = router.pathname.split('/');
    let pidIndex = array.findIndex(e => e === '[pid]');
    
    /*
      input: '/members/practice-management/jobs/[pid]'
      output: '/members/practice-management/jobs'
    */
    let newPath = array.slice(0, pidIndex).join('/'); 
    if (newPath === path) {
      return true;
    }
    return false;
  }
  return false;
};
