import { useContext } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { ProgressContext } from '../Context/progressContext';

export default function ProgressBar() {
    const { state: progressContext, update: updateProgress } = useContext(ProgressContext);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000, opacity: '0.6 !important' }}
                open={progressContext.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
