import { 
    useContext,
    useEffect,
    useState 
} from 'react';
import { useRouter } from 'next/router';

import { 
    Button,
    IconButton,
    ListItemIcon,
    MenuItem,
    Tooltip,
    Typography
} from '@mui/material';

import { Brightness4, Brightness7, PersonOutline } from '@mui/icons-material';
import { useTheme } from 'next-themes'

import { userAccountMenu,staffUserAccountMenu } from '../../navigation/navigation';
import { pusherClient } from '../../lib/pusher';
import { PusherContext } from '../Context/pusherContext';
import { UserContext } from '../Context/userContext';
import { StyledMenu } from './StyledMenu';

export default function UserAccountMenu(props) {
    const { state: userContext, update: updateUser } = useContext(UserContext);
    const { state: pusherContext, update: updatePusherContext} = useContext(PusherContext);
    const { theme, setTheme } = useTheme()

    const [anchorEl, setAnchorEl] = useState(null);
    const [profilePicLoaded, setProfilePicLoaded] = useState(false);

    const open = Boolean(anchorEl);

    const router = useRouter();

    const profilePic = `${process.env.S3_URL}/user-profile-pics/${userContext?.user?.profilePic}?not-from-cache-please`
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItemClicked = (item) => {
        router.replace(item.href);
    };

    useEffect(() => {
        if (userContext?.user?.id) {
            let myChannel = pusherClient.subscribe(`private-online-users-${userContext?.user?.id}`);
            myChannel.bind('pusher:subscription_succeeded', () => {
                // 
            });

            let onlineUsersChannel = pusherClient.subscribe('pusher-online');
            onlineUsersChannel.bind('user-joined', data => {
                updatePusherContext({
                    ...pusherContext,
                    onlineUsers: data.userIds
                });
            });
        }
        return () => {
            pusherClient.unsubscribe(`private-online-users-${userContext?.user?.id}`);
            pusherClient.unbind('pusher:subscription_succeeded');
            pusherClient.unbind('user-joined');
        };
    }, [userContext?.user?.id]);

    const toggleColorMode = () => {
        if (theme === 'dark') {
            setTheme('light')
        } else {
            setTheme('dark')
        }
    }

    return (
        <>
            {/* <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
                {theme === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton> */}
            <Tooltip title='Account'>
                {props.type && !userContext?.user?.id ? (
                    <Button 
                        variant='contained'
                        className='bg-primary-500 hover:bg-primary-500  whitespace-nowrap text-white'
                        startIcon={<PersonOutline />}
                        onClick={handleClick}
                    >
                        Log In
                    </Button>
                ) : (
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'notification-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {!profilePic ? (
                            <PersonOutline />
                        ) : (
                            <div className='flex'>
                                {!profilePicLoaded && (<PersonOutline />)}
                                <img
                                    alt="Profile Pic" 
                                    width={24}
                                    height={24}
                                    size='small'
                                    src={profilePic}
                                    crossOrigin='anonymous'
                                    className={`rounded-full ${!profilePicLoaded ? 'hidden' : ''}`}
                                    onLoad={() => setProfilePicLoaded(true)}
                                />
                            </div>
                        )}
                    </IconButton>
                )}
            </Tooltip>
            <StyledMenu
                anchorEl={anchorEl}
                id="notification-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
            >
                { userContext?.user?.isStaff ? staffUserAccountMenu.map((item) => (
                    <MenuItem    
                        key={item.name}
                        onClick={() => menuItemClicked(item)}
                        className='flex'
                    >
                        <ListItemIcon>
                            <item.icon fontSize='small'/>
                        </ListItemIcon>
                        <Typography component='div'>
                            {item.name}
                        </Typography>
                    </MenuItem>
                )) : userAccountMenu.map((item) => (
                    <MenuItem    
                        key={item.name}
                        onClick={() => menuItemClicked(item)}
                        className='flex'
                    >
                        <ListItemIcon>
                            <item.icon fontSize='small'/>
                        </ListItemIcon>
                        <Typography component='div'>
                            {item.name}
                        </Typography>
                    </MenuItem>
                ))
                }
            </StyledMenu>
        </>
    )
}
